import React from 'react';
import './App.css';
import bg1 from './assets/IMG_8471.JPG';
import bg2 from './assets/simple2.jpeg';
import bg3 from './assets/Raindrops.jpeg';

function App() {
  return (
    <div className="App">
      {/* First Section */}
      <section className="section section-1" style={{ backgroundImage: `url(${bg1})` }}>
        <div className="overlay">
          <h1>Welcome to My Space</h1>
          <p>Capturing moments, telling stories, and creating memories.</p>
        </div>
      </section>

      {/* Second Section */}
      <section className="section section-2" style={{ backgroundImage: `url(${bg2})` }}>
        <div className="overlay">
          <h1>About Me</h1>
          <p>Discover more about my journey and what I love doing.</p>
        </div>
      </section>

      {/* Third Section */}
      <section className="section section-3" style={{ backgroundImage: `url(${bg3})` }}>
        <div className="overlay">
          <h1>Contact Me</h1>
          <p>Let's work together on amazing projects.</p>
        </div>
      </section>
    </div>
  );
}

export default App;
